.avatar-comment {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
  }
  
  .avatar {
    margin-right: 16px;
    margin-bottom: 1.6em;
    
  }
  
  .user-info {
    flex-grow: 1;
    box-shadow:  2px grey;
  }
  @media (max-width:768) {
.p-avatar.p-avatar-lg {
    width: 20%;
   
    font-size: 1.5rem;
}
    
}