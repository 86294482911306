@media (max-width:426px){
    .on-hoverA {
        margin-top: 17% !important;
        text-align: center !important;
        margin-left: 24%;
        max-width: 100%;
        width: 200px;
        height: 200px;
        border-radius: 100%;
    }
}