/* .main-container{
    margin-left: 15%;
    width: 100%;
}
.child-container{
    margin-left: 9%;
    margin-Right: 9%;
    display: flex;
   
} */
/* .mainNav{
    margin-left: 15%;
} */

/* @media (max-width:1024px) {
    .main-container{
        margin-left:0px;
    }
    .child-container{
        margin-left:5%;
        margin-Right:5%;
         display: flex;
         
    }
    .mainNav{
        margin-left: 10%;
    }
}
@media (max-width:900px) {
    .main-container{
        margin-left:0px;
    }
    .child-container{
        margin-left:0px ;
        margin-Right: 0px ;
         display: flex;
        
    }
    .mainNav{
        margin-left: 4%;
    }
} */




/* General Styles */
.main-container {
    margin-left: 15%;
    width: 100%;
}

.child-container {
    margin-left: 9%;
    margin-right: 9%; /* Fixed typo from "margin-Right" */
    display: flex;
}

/* Responsive Design for Medium Screens (Tablets, Laptops) */
@media (max-width: 1024px) {
    .main-container {
        margin-left: 0; /* Reset margin for smaller screens */
    }

    .child-container {
        margin-left: 5%;
        margin-right: 5%; /* Adjust spacing for medium screens */
        display: flex;
    }

    .mainNav {
        margin-left: 10%;
    }
}

/* Responsive Design for Small Screens (Mobile Phones) */
@media (max-width: 900px) {
    .main-container {
        margin-left: 0; /* Full width for smaller screens */
    }

    .child-container {
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-direction: column; /* Stack items vertically */
    }

    .mainNav {
        margin-left: 4%; /* Adjust spacing */
    }
}

/* Extra Small Screens (Optional for Ultra Small Devices) */
@media (max-width: 600px) {
    .main-container {
        margin-left: 0;
        padding: 0 2%; /* Add some padding for ultra-small screens */
    }

    .child-container {
        margin: 0;
        flex-direction: column;
        align-items: center; /* Center align items */
    }

    .mainNav {
        margin-left: 2%;
    }
}
