/* styles.css */
.image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .gallery-item {
    flex: 0 0 calc(50% - 2px); /* Two items per row with no spacing */
    position: relative;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .gallery-item .hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item:hover .hover-overlay {
    opacity: 3;
  }
  
  .gallery-item .nav-button {
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  