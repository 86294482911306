#crtSidebarInner {
  height: 100%;
  padding: 15px;
  overflow: auto;
  
  

}
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  position: relative;
  overflow: visible;
  
}

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: auto;
  /* max-width: 100%; */
  outline: 0;
  direction: ltr;
 
 max-height: none;
 background-color: #4EC0F4;
 
 
}



.text-upper {
  color: white;
  font-weight: normal;
  line-height: 1.2;
  padding-top: 29px;
  padding-left: 40px;
  letter-spacing: 2px;
  text-transform: uppercase;
 
}

.text-muted {
  color: white;
  opacity: .65;
  font-size: 1em;
   margin-left: 31px;
  margin-top: 10px;
  
}

.text-upper1{
 
  color: inherit;
  margin-top: 20px; 
  text-align: center; 
  font-size: 1em;
  opacity: .65;
  font-weight: normal;
}



.search-container {
  position: relative;
  display: inline-block;
}

.search-input {
  width: 130%;
  height: 45px;
  padding: 15px 45px 15px 15px;
  line-height: normal;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  background-color: whitesmoke;
  margin-top: 20px;
  border-radius: 3px;
  margin-bottom: 48px;

}


.search-icon {
  position: absolute;
  top: 39%;
 left: 105% ;
  transform: translateY(-50%);
  color: black; 
  cursor: pointer;
}

.text-upper2{
 
  color: inherit;
  margin-top: 40px; 
  text-align: center; 
  font-size: 1.143em;
  opacity: .65;
  font-weight: normal;
}
.container {
  display: flex;
  align-items: center;
}

.left {
  flex: 1; 
  text-align: left;
}

.right {
  color: inherit;
  cursor: pointer;
  background-color: transparent;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  padding-left: 80px;
  margin-top: 23px;
  font-size: 1.143em;
  opacity: .65;
  
}

.img-class{
  max-width: 100%;
  height: auto;
  margin-left: 32px;
}
.container1 {
  display: flex;
  align-items: center;
}
.left1 {
  flex: 1; 
  text-align: left;
}

.right1 {
  color: inherit;
  cursor: pointer;
  background-color: transparent;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  padding-left: 80px;
  margin-top: 23px;
  font-size: 1.143em;
  opacity: .65;
  
}
.text-upper2{

  text-align: left;
}

