
.parent-div {
    background-color: white;
    border: 1rem solid white; 
    display: flex; 
    flex-direction: column;
}

.child-div  {
    background-color: white;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    box-shadow: 0px 0px 18px 0px #abc4ff;
}
h2 {
    color: rgb(0, 0, 0);
    font-family: 'Julius Sans One', sans-serif;
}
.text-muted {
    display: flex; 
    justify-content: center; 
    align-items: center;
}

.links{
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.clear-mrg>:last-child {
    margin-bottom: 0!important;
}
.btn-dnld{
    text-align: center;
    border-radius: 35px ;
    background-color: #07AAF5;
    color: #fff;
    border: 2px solid transparent;
    box-shadow: transparent 0 0 0;
    font-size:1rem;
    padding: 0.8rem 2rem;
    font-weight: bold
}
.btn-dnld-cv{
  
    margin-left: 38%;
    margin-bottom: 2%;
    

}
.line1{
    text-align: center;
    border-bottom: 0.5px solid #424141;
    margin-top: 4%;
    margin-bottom: 15px
}
.line2{
    width: 40vw;
    text-align: center;
    border-bottom: 0.3px solid #868585c4;
    margin-top: 2%;
    margin-bottom: 15px
}
.personal-info-lang{
    width: 100%;
    display: grid;
    grid-template-columns: 35fr  35fr;
    font-family: 'Julius Sans One', sans-serif;
    font-size: 1em;
    font-weight: 300px;
    margin-bottom: 1.571em;
    color: #757575;
    letter-spacing: 1px;
}
.Personal-info{
    grid-column: 1/2;
}
.lang{
    grid-column: 2/3;
}
.table td{
    font-size: 0.8em;
}
.style-list{

    font-weight: 700;
    font-size: 0.9em;
    color: black;
    font-size: 16px;
    letter-spacing: .02em;
    line-break: 1px;
    line-height: 2em;
    
}

.css-e784if-MuiTypography-root{

    font-weight: bold;
    text-align: center;
}
