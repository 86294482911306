@import './assets/plugins/bootstrap/css/bootstrap.min.css';

@import './assets/css/main.css';

@import './assets/css/theme2.css';

@import './assets/css/custom.css';

@import './assets/plugins/charts-c3/c3.min.css';

@import './assets/plugins/summernote/dist/summernote.css';

@import './assets/plugins/jvectormap/jvectormap-2.0.3.css';

@import './assets/plugins/bootstrap-multiselect/bootstrap-multiselect.css';

@import './assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css';

@import './assets/plugins/fullcalendar/fullcalendar.min.css';

@import "primereact/resources/themes/lara-light-indigo/theme.css";

@import "primereact/resources/primereact.min.css";                                       

@import './assets/plugins/jquery-steps/jquery.steps.css';

@font-face {
    font-family: Amsterdam;
    src: url('./../assets/font/The\ Amsterdam.ttf');
}

@font-face {
    font-family: Amsterdam-italic;
    src: url('./../assets/font/The\ Amsterdam\ Italic.ttf');
}

@font-face {
    font-family: Amsterdam;
    src: url('./../assets/font/The\ Amsterdam.ttf');
}

@font-face {
    font-family: Amsterdam;
    src: url('./../assets/font/The\ Amsterdam.ttf');
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

body {
    padding: 0;
    margin: 0;
    background-color: var(--white);
    background-image: url("./assets/images/bgfinal1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    font-size: 16px;
    line-height: 1.4em;
    letter-spacing: .08em;
}

/* home-page product carousel */
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    display: none;
}

.p-carousel .p-carousel-indicators {
    padding: 40px !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #BDBDBD !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef !important;
    width: 1rem !important;
    border-radius: 100% !important;
    height: 1rem !important;
}


/* Product listing varient dropdown */
.productVarient .card {
    padding: 0 !important;
    margin: 0;
    margin-top: 1vh;
}

.productVarient .p-dropdown {
    background: var(--green);
    padding: 5px;
}

.productVarient .p-dropdown .p-dropdown-label.p-placeholder,
.p-inputtext {
    color: var(--white) !important;
    font-weight: 400;
    font-size: 17px !important;
    /* padding:1.5vw !important; */

}

.productVarient .p-icon {
    color: var(--white) !important;
}

/* product detail varient */
.productDetailPage .card {
    min-width: 168px !important;
    width: 0px !important;
    margin-bottom: 13px;
}

.productDetailPage .p-dropdown .p-dropdown-label.p-placeholder,
.p-inputtext {
    font-size: 23px !important;
}

.productDetailPage .p-dropdown {
    padding: 2px;
}

/* menus template */
/* .productVarient .p-dropdown .p-dropdown-label.p-placeholder, .p-inputtext {
    color: black !important;
    font-weight: 200;
    font-size: 16px !important;
    padding: 4px !important;
} */

.website-template .p-dropdown {
    background-color: transparent;
    height: 35px!important;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border: 1px solid gray;
}

.website-template .p-dropdown .p-dropdown-label{
    color: #000!important;
    font-size: 14px!important;
}



@media(max-width:1025px) {
    .productVarient .p-dropdown {
        padding: 4px;
    }

    .productDetailPage .p-dropdown .p-dropdown-label.p-placeholder,
    .p-inputtext {
        font-size: 16px;
    }

    /* product detail */
    .productDetailPage .card {
        min-width: 120px !important;
    }

    .productDetailPage .p-dropdown .p-dropdown-label.p-placeholder,
    .p-inputtext {
        font-size: 16px !important;
    }
}

@media(max-width: 769px) {
    .productVarient .p-dropdown {
        padding: 0 !important;
    }

    .productVarient .card {
        min-width: 118px;
    }

}

@media(max-width:426px) {
    .p-carousel .p-carousel-indicators {
        padding: 30px !important;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        width: 0.8rem !important;
        height: 0.8rem !important;
    }

}

.invalidDataError {
    color: red !important;
}

.spinner_class {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000033;
    z-index: 99999;
    position: fixed;
    width: 100vw;
    overflow: hidden;
    top: 0;
}


.auth_left {
    align-items: center;
    background: #50af31 !important;
    background-image: url('assets/images/bg-pattan.png') !important;
    display: flex;
    height: 100vh;
    padding: 0 20px;
    width: 100% !important;
}


.auth_left .card {
    right: 0 !important;
    width: 500px;
    margin: auto;
}

.cart_card {
    width: 50%;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    margin-left: auto;
}

table,
thead,
tbody,
th,
td,
tr {
    text-align: left !important;
}