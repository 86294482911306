#vertical-navbar {
    position: sticky;
    top: 0;
   overflow-y: auto;
    background-color: white;
    
    
  }
  
  /* .content {
    flex-grow: 1;
    padding: 20px;
  }
  
  .section {
    padding: 20px;

  } */