.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
  background-color: #39374e !important;
  color: #babdbf !important;
  border-color: #39374e !important;
}

.nav-link-gdc-selected {
  font-weight: bold !important;
}

div.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

div.font-roboto {
  font-family: "Robot", sans-serif;
}

div.font-opensans {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

/* div.rtl{ */
div.rtl {
  direction: rtl !important;
  text-align: right;
}

.sticky-top {
  width: calc(100% - 280px);
  position: fixed;
}

.marginTop {
  margin-top: 7.2% !important;
}

:root {
  --alice: "Alice";
  --normal: "normal";
  --inter: "Inter";
  --gray: #4b4b4b;
  --gray-1: #676767;
  --lightGray: #9c9c9c;
  --white: #ffffff;
  --light: #faf8ec;
  --light-1: #d5d5d5;
  --light-2: #e8e9e9;
  --light-3: #F4F4F4;
  --dark: #323232;
  --dark-1: #272626;
  --green: #7fb401;
  --green-1: #c6ce3c;
  --green-2: #97bf00;
  --green-3: #719924;
  --green-4: #599408;
  --green-5: #60b500;
  --mustard: #f9c500;
}

/* breadcrmb css */
.top-left-heading {
  font-weight: 700;
  font-size: 18px;
  color: black;
}

.route-heading {
  font-size: 14px;
}

.card {
  margin: 0;
}

.input-container {
  position: relative;
}

.search-input {
  padding-right: 30px; /* Adjust the padding to leave space for the icon */
}

.search-icon-container {
  position: absolute;
  right: 15px; /* Adjust the right position to align the icon */
  top: 50%;
  transform: translateY(-50%);
}

.search-icon {
  display: block;
}
