/* Navbar.css */
/* Style the navigation list to be horizontal */
.nav-list {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  /* Style each navigation item */
  .nav-item {
    margin-right: 20px;
    font-weight: bold;
  }
  
  /* Style the button within the navigation item */
  .nav-menu-button {
    border-radius: 100%;
    
  }
ul{
    list-style-type: none;
    
}
.topbar {
    background-color: transparent;
    box-shadow: none;
  }
  
  .container1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
  
  .logo {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 9%;
    
  }
  
  .link {
    color: black;
    text-decoration: none;
    margin-right: 15px;
  }
  
  .item {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  /* Styling for the offcanvas */
  .offcanvas-header {
    background-color: transparent;
    border-bottom: none;
  }
  
  .offcanvas-title {
    color: white;
  }
  
  .offcanvas-body {
    background-color:white;
    color: #000;
  }
  